var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "list" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.onReachBottom,
              expression: "onReachBottom"
            }
          ],
          attrs: {
            "infinite-scroll-disabled": "loading",
            "infinite-scroll-distance": "10"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "listCt",
              on: {
                click: function($event) {
                  return _vm.handleDetail(item)
                }
              }
            },
            [
              _c("div", { staticClass: "listLeft" }, [
                _c("img", { attrs: { src: item.imagePath } })
              ]),
              _c("div", { staticClass: "listRight" }, [
                _c("p", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
                _c("p", { staticClass: "time" }, [
                  _c("img", {
                    staticClass: "time_img",
                    attrs: { src: require("@/assets/img/park/add.png") }
                  }),
                  _c("span", [_vm._v(_vm._s(item.addr))])
                ]),
                _c("p", { staticClass: "time" }, [
                  _c("img", {
                    staticClass: "time_img",
                    attrs: { src: require("@/assets/img/park/date.png") }
                  }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(String(item.createTime).slice(0, 10)) +
                        " - " +
                        _vm._s(String(item.endTime).slice(0, 10)) +
                        " "
                    )
                  ])
                ])
              ])
            ]
          )
        }),
        0
      ),
      !_vm.loading
        ? _c("div", { staticClass: "nodata" }, [_vm._v("已无更多数据")])
        : _vm._e(),
      !_vm.isEnd && _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [_c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inforList" }, [
      _c("p", { staticClass: "title" }, [
        _c("span", { staticClass: "left" }, [_vm._v("园区活动")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }