<template>
  <div>
    <div class="inforList">
      <p class="title">
        <span class="left">园区活动</span>
      </p>
    </div>
    <div class="list">
      <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
        <div class="listCt" v-for="item in list" :key="item.id" @click="handleDetail(item)">
          <div class="listLeft">
            <img :src="item.imagePath" />
          </div>
          <div class="listRight">
            <p class="title">{{ item.name }}</p>
            <p class="time">
              <img src="@/assets/img/park/add.png" class="time_img" />
              <span>{{ item.addr }}</span>
            </p>
            <p class="time">
              <img src="@/assets/img/park/date.png" class="time_img" />
              <span> {{ String(item.createTime).slice(0, 10) }} - {{ String(item.endTime).slice(0, 10) }} </span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="nodata">已无更多数据</div>
      <div v-if="!isEnd && loading" class="loading">
        <mt-spinner color="#178CF9" type="snake"></mt-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { listActivity } from '@/api/park'

export default {
  data() {
    return {
      swipeList: [],
      loading: false,
      pageNo: 0,
      isEnd: false,
      list: []
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.isEnd = false
      this.list = []
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const { success, result } = await listActivity({
          pageSize: 10,
          pageNo
        })
        const totalPage = result.pages
        if (success) {
          if (+pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    },
    handleDetail(item) {
      this.$router.push(`/activity/detail/${item.id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.inforList {
  padding: 15px;
  background: #fff;

  .title {
    margin-bottom: 0;
    font-family: 'Source Han Sans CN';
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      border-left: 3px solid #178cf9;
      height: 16px;
      line-height: 16px;
      color: #333;
      font-weight: 700;
      font-size: 15px;
      padding-left: 10px;
    }
  }
}

.list {
  padding: 0 15px;
}

.system-message-box {
  height: 93vh;
  overflow: scroll;
  margin-bottom: 50px;

  .load-all {
    /* 数据加载完毕提示信息 */
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #c8c8cf;
  }
}

.listCt {
  padding-bottom: 18px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Source Han Sans CN';

  .listLeft {
    width: 133px;
    height: 93px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;

    img {
      // width:100%;
      width: 133px;
      height: 93px;
    }
  }

  .listRight {
    width: 203px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #333;
      font-size: 16px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
    }

    .time {
      font-size: 11px;
      color: #999999;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .time_img {
        margin-right: 6px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
